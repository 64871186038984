@import "../variables";

agm-map {
    height: calc(100vh - 42rem);
    width: 100%;
    position: relative;
    display: block;
}

$factor: 0.8;
$marker-width: 53px * $factor;
$marker-height: 71px * $factor;

agm-map:after {
    width: $marker-width;
    height: $marker-height;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -1 * $marker-width * 0.5;
    margin-top: -$marker-height;
    background: url('../../assets/images/icon-map-marker.svg');
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
}

.geocoding {
    .fields {
        padding: 0 3rem;
        margin: 3rem 0;

        ion-input {
            font-size: 1.3rem;
            line-height: 1.7rem;
            color: var(--theme-color-darker);
        }
    }

    .invalid {
        font-size: 1.3rem;
        line-height: 1.7rem;
        color: var(--theme-color-danger);
    }
}

.geocoding-or-label {
    display: block;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
}

button.geocode-button {
    margin: 3rem 0 2rem 0;
}
