.icon .primary {
    fill: var(--theme-color-primary);
}

.icon .darker {
    fill: var(--theme-color-darker);
}

.icon .primary-stroke {
    stroke: var(--theme-color-primary);
}

.icon-unchecked .circle {
    stroke: var(--theme-color-primary);
    fill: var(--theme-color-secondary);
}
