@import "../variables";

app-number {
    display: block;

    .circle {
        --padding-start: 0;
        --padding-end: 0;
        --inner-padding-start: 0;
        --inner-padding-end: 0;

        display: flex;
        align-items: center;
        width: 10rem;
        height: 10rem;
        margin: 0 auto 1rem;

        cursor: pointer;
        border-radius: 100%;
        border: 2px solid var(--theme-color-primary);
    }

    .value {
        width: 100%;

        color: var(--theme-color-primary);
        font-size: 3.2rem;
        text-align: center;
    }

    input[type='number'], input[type='text'] {
        margin-top: 30px;
        margin-bottom: 30px;

        text-align: center;
    }
}
