ion-app {
    .spinner-background {
        position: fixed;
        background-color: white;
        height: 100vh;
        width: 100vw;
        left: 0;
        right: 0;
        top: 7rem;
        bottom: 0;

        display: flex;
        align-items: center;

        ion-spinner {
            height: 7rem;
            width: 7rem;
            margin-top: -7rem;
            margin-left: auto;
            margin-right: auto;

            circle {
                stroke: var(--theme-color-primary) !important;
            }
        }
    }
	.actionsheet-destructive-red {
		color: #f53d3d !important;
	}
}
