@import "../variables";

page-select-questionnaire {

    .spinner-background {
        position: fixed;
        background: white;
        width: 100vh;
        height: 100vh;
        z-index: 10;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
