@import "../variables";

app-dashboard-header {
    .title {
        margin: 0;
    }

    header-progress {
        .progress {
            position: absolute;
            left: 0;
            right: 0;
            height: 0.3rem;
            background-color: var(--theme-color-primary);
        }
    }

    .dashboard-icon {
        display: inline-block;
        vertical-align: top;
        padding-right: 1.8rem;
        padding-left: 1.8rem;

        svg {
            display: inline-block;
            vertical-align: top;
            width: 23px;
            height: 23px;

            path {
                fill: #fff;
            }
        }
    }

    img {
        display: inline-block;
        vertical-align: top;
        width: 9.0rem;
    }

    .toolbar-title {
        font-size: 0;
    }
}

.app-bar__toolbar {
    padding-left: .8rem !important;
}

.app-bar__toolbar--compact {
    padding-top: .8rem !important;
    padding-bottom: .8rem !important;
}

.app-bar__toolbar-main {
    display: flex;
    justify-content: space-between;
    gap: 1.6rem;
}

.app-bar__back {
    --padding-top: .8rem;
    --padding-end: .8rem;
    --padding-bottom: .8rem;
    --padding-start: .8rem;
}

.app-bar__back-icon {
    color: var(--theme-color-light);
    width: 2.4rem;
    height: 2.4rem;
}

.app-bar__title {
    margin-left: 1.6rem;
}

.app-bar__help {
    display: inline-block;
    background: var(--theme-color-dark);
    border-radius: 50%;
    height: auto !important;
    padding: .8rem 0 !important;
}

.app-bar__help-icon {
    width: 2.4rem;
    height: 2.4rem;
}
