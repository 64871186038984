@import "../variables";
@import "../mixin";

app-checkbox,
app-checkbox-searchable {
    .multiple-choice-description {
        display: block;
        color: var(--theme-color-dark);
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }

    .choice-description {
        font-size: 1.4rem;
        line-height: 1.42857143;
        text-align: left;
        word-wrap: break-word;
    }

    .choice-subtitle {
        font-size: 1rem;
        text-align: left;
        line-height: 1.4;
        word-wrap: break-word;
    }

    .choice-container {
        display: inline-flex;
        flex-direction: column;
    }

    @media (max-width: 576px) { // TODO lookup xs size
        .choice-container {
            width: 100%;
        }
    }

    .choice {
        display: flex;
        padding: .8rem 2rem;
        margin-bottom: .8rem;
        border-radius: 2rem;
        align-items: center;

        background-color: var(--theme-color-light);
        border: 0.1rem solid var(--theme-color-border);
        word-break: break-word;

        cursor: pointer;

        &.checked {
            background: var(--theme-color-primary-light);
            border-color: var(--theme-color-primary);
        }

        .chip--full {
            display: block;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
        }
    }

    .icon {
        display: inline-block;
        vertical-align: top;
        width: 32px;
        min-width: 32px;
        max-width: 32px;
        height: 32px;
        min-height: 32px;
        max-height: 32px;
        box-sizing: content-box;
        margin-right: 2rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        // SASS quirck resolving source /assets/images/icon-unchecked.svg
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBjbGFzcz0iaWNvbi11bmNoZWNrZWQiPgogICAgPGRlZnM+CiAgICAgICAgPHJlY3QgaWQ9InBhdGgtMSIgeD0iMCIgeT0iMCIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiByeD0iMTYiPjwvcmVjdD4KICAgIDwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIxMi01LTAwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDEuMDAwMDAwLCAtNDkxLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0iR3JvdXAtMi1Db3B5LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQxLjAwMDAwMCwgNDkxLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9IlJlY3RhbmdsZS04Ij4KICAgICAgICAgICAgICAgICAgICA8cmVjdCBjbGFzcz0iY2lyY2xlIiBzdHJva2U9IiMzRTlGRkYiIGZpbGw9InJnYmEoNjIsIDE1OSwgMjU1LCAwLjEpIiBzdHJva2Utd2lkdGg9IjIiIHg9IjEiIHk9IjEiIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCIgcng9IjE1Ij48L3JlY3Q+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=');
        border-radius: 50%;
    }
    .icon-checked {
        // SASS quirck resolving source /assets/images/icon-checkmark.svg
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIxMi01LTAwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDEuMDAwMDAwLCAtMzE2LjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0iR3JvdXAtMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDEuMDAwMDAwLCAzMTYuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlLTgiIGNsYXNzPSJkYXJrZXIiIHg9IjAiIHk9IjAiIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgcng9IjE2Ij48L3JlY3Q+CiAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aCIgZmlsbD0iI0ZGRkZGRiIgcG9pbnRzPSIxMy43MTc2MjIxIDIzLjQ2NSA4IDE3Ljc0NzM3NzkgMTAuMDE1NDYxOCAxNS43MzE5MTYxIDEzLjcxNzYyMjEgMTkuNDE5NzgyNCAyMy4xMzc0MDQ1IDEwIDI1LjE1Mjg2NjIgMTIuMDI5NzU1OCI+PC9wb2x5Z29uPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K');
        background-color: #25425F;
    }
}

app-checkbox-searchable {
    .choice-container {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        box-sizing: border-box;
    }
}
