@import "../variables";

.button-md-light,
.button-ios-light {
  color: var(--theme-color-dark);
}

.button-md,
.button-ios {
  text-transform: none;
  font-size: 1.6rem;
  height: 5.2rem;
  box-shadow: none;
}

.bar-button-md,
.bar-button-ios {
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  text-transform: none;
  border-radius: 0;
}
