@import "../variables";

.task-action-holder-remarks {
    border-top: 1px solid #e8e8e8;
    text-align: center;
    padding: 4rem 2rem;

    .task-action-holder-remarks-label {
        font-size: 1.2rem;
        color: var(--theme-color-light-text);
        margin: 0 0 0.5rem 0;
    }
}
