@import "../variables";

page-support {

    .bold-text {
        display: block;
    }

    .contact {
        padding: 0 2rem;
        margin-bottom: 4rem;

        ion-row {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        .text-color-dark {
            font-size: 1.4rem;
        }
    }

    .question {
        border-bottom: .1rem solid var(--theme-color-border);
        padding: 1rem 1.6rem .9rem;

        &:last-child {
            border-bottom: none;
            padding-bottom: 1rem;
        }

        p {
            margin: 0.6rem 0;
        }
    }

    .section-title {
        background-color: var(--theme-color-light-grey);
        padding: 1rem 0 1rem 2rem;
    }

    .question-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.6rem;
    }

    .question-answer {
        display: none;
        color: var(--theme-color-text-gray);
    }

    .open-faq-item {
        .question-answer {
            display: block;
        }

        .question-toggle {
            transform: rotate(-180deg);
        }
    }

    .privacy-policy {
        text-align: right;

        span {
            cursor: pointer;
        }
    }

    @media (max-width: 374px) {
        .iphone-5-col-12 {
            -webkit-flex: 0 0 100%;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
        }

        .privacy-policy {
            margin-top: 2rem;
            text-align: left;
        }
    }
}

.support__items {
    margin-right: -1.6rem;
    margin-left: -1.6rem;
}
