@import "../mixin";
@import "../variables";

@mixin ionVariables($colors) {

    --ion-default-font: #{$font-family-base};
    --ion-font-family: #{$font-family-base};
    --ion-text-color: #{map-get($colors, text)};
    --ion-text-color-rgb: #{splitHexToRGB(map-get($colors, text))};
    --ion-margin: #{$base-spacing};
    --ion-border-color: #{map-get($colors, lightGrey)};

    --ion-background-color: #{map-get($colors, light)};
    --ion-background-color-rgb: #{splitHexToRGB(map-get($colors, light))};
    --ion-item-background: #{map-get($colors, light)};
    --ion-item-border-color: #{map-get($colors, border)};
    --ion-toolbar-background: #{map-get($colors, background)};
    --ion-tab-bar-background: #{map-get($colors, light)};
    --ion-card-background: #{map-get($colors, light)};

    --ion-color-step-50: #{mix(map-get($colors, dark), map-get($colors, light), 5%)};
    --ion-color-step-100: #{mix(map-get($colors, dark), map-get($colors, light), 10%)};
    --ion-color-step-150: #{mix(map-get($colors, dark), map-get($colors, light), 15%)};
    --ion-color-step-200: #{mix(map-get($colors, dark), map-get($colors, light), 20%)};
    --ion-color-step-250: #{mix(map-get($colors, dark), map-get($colors, light), 25%)};
    --ion-color-step-300: #{mix(map-get($colors, dark), map-get($colors, light), 30%)};
    --ion-color-step-350: #{mix(map-get($colors, dark), map-get($colors, light), 35%)};
    --ion-color-step-400: #{mix(map-get($colors, dark), map-get($colors, light), 40%)};
    --ion-color-step-450: #{mix(map-get($colors, dark), map-get($colors, light), 45%)};
    --ion-color-step-500: #{mix(map-get($colors, dark), map-get($colors, light), 50%)};
    --ion-color-step-550: #{mix(map-get($colors, dark), map-get($colors, light), 55%)};
    --ion-color-step-600: #{mix(map-get($colors, dark), map-get($colors, light), 60%)};
    --ion-color-step-650: #{mix(map-get($colors, dark), map-get($colors, light), 65%)};
    --ion-color-step-700: #{mix(map-get($colors, dark), map-get($colors, light), 70%)};
    --ion-color-step-750: #{mix(map-get($colors, dark), map-get($colors, light), 75%)};
    --ion-color-step-800: #{mix(map-get($colors, dark), map-get($colors, light), 80%)};
    --ion-color-step-850: #{mix(map-get($colors, dark), map-get($colors, light), 85%)};
    --ion-color-step-900: #{mix(map-get($colors, dark), map-get($colors, light), 90%)};
    --ion-color-step-950: #{mix(map-get($colors, dark), map-get($colors, light), 95%)};

    --ion-color-primary: #{map-get($colors, primary)};
    --ion-color-primary-rgb: #{splitHexToRGB(map-get($colors, primary))};
    --ion-color-primary-contrast: #{map-get($colors, light)};
    --ion-color-primary-contrast-rgb: #{splitHexToRGB(map-get($colors, light))};
    --ion-color-primary-shade: #{darken(map-get($colors, primary), 3%)};
    --ion-color-primary-tint: #{lighten(map-get($colors, primary), 3%)};

    --ion-color-secondary: #{map-get($colors, secondary)};
    --ion-color-secondary-rgb: #{splitHexToRGB(map-get($colors, secondary))};
    --ion-color-secondary-contrast: #{map-get($colors, primary)};
    --ion-color-secondary-contrast-rgb: #{splitHexToRGB(map-get($colors, primary))};
    --ion-color-secondary-shade: #{darken(map-get($colors, secondary), 3%)};
    --ion-color-secondary-tint: #{lighten(map-get($colors, secondary), 3%)};

    --ion-color-success: #{map-get($colors, success)};
    --ion-color-success-rgb: #{splitHexToRGB(map-get($colors, success))};
    --ion-color-success-contrast: #{map-get($colors, light)};
    --ion-color-success-contrast-rgb: #{splitHexToRGB(map-get($colors, light))};
    --ion-color-success-shade: #{darken(map-get($colors, success), 3%)};
    --ion-color-success-tint: #{lighten(map-get($colors, success), 3%)};

    --ion-color-warning: #{map-get($colors, warning)};
    --ion-color-warning-rgb: #{splitHexToRGB(map-get($colors, warning))};
    --ion-color-warning-contrast: #{map-get($colors, dark)};
    --ion-color-warning-contrast-rgb: #{splitHexToRGB(map-get($colors, dark))};
    --ion-color-warning-shade: #{darken(map-get($colors, warning), 3%)};
    --ion-color-warning-tint: #{lighten(map-get($colors, warning), 3%)};

    --ion-color-danger: #{map-get($colors, danger)};
    --ion-color-danger-rgb: #{splitHexToRGB(map-get($colors, danger))};
    --ion-color-danger-contrast: #{map-get($colors, light)};
    --ion-color-danger-contrast-rgb: #{splitHexToRGB(map-get($colors, light))};
    --ion-color-danger-shade: #{darken(map-get($colors, danger), 3%)};
    --ion-color-danger-tint: #{lighten(map-get($colors, danger), 3%)};

    --ion-color-dark: #{map-get($colors, dark)};
    --ion-color-dark-rgb: #{splitHexToRGB(map-get($colors, dark))};
    --ion-color-dark-contrast: #{map-get($colors, light)};
    --ion-color-dark-contrast-rgb: #{splitHexToRGB(map-get($colors, light))};
    --ion-color-dark-shade: #{darken(map-get($colors, dark), 3%)};
    --ion-color-dark-tint: #{lighten(map-get($colors, dark), 3%)};

    --ion-color-light: #{map-get($colors, light)};
    --ion-color-light-rgb: #{splitHexToRGB(map-get($colors, light))};
    --ion-color-light-contrast: #{map-get($colors, dark)};
    --ion-color-light-contrast-rgb: #{splitHexToRGB(map-get($colors, dark))};
    --ion-color-light-shade: #{darken(map-get($colors, light), 3%)};
    --ion-color-light-tint: #{lighten(map-get($colors, light), 3%)};

    --ion-color-navbar: #{map-get($colors, background)};
    --ion-color-navbar-rgb: #{splitHexToRGB(map-get($colors, navbar))};
    --ion-color-navbar-contrast: #{map-get($colors, light)};
    --ion-color-navbar-contrast-rgb: #{splitHexToRGB(map-get($colors, light))};
    --ion-color-navbar-shade: #{darken(map-get($colors, navbar), 3%)};
    --ion-color-navbar-tint: #{lighten(map-get($colors, navbar), 3%)};

    --ion-color-darker: #{map-get($colors, darker)};
    --ion-color-darker-rgb: #{splitHexToRGB(map-get($colors, darker))};
    --ion-color-darker-contrast: #{map-get($colors, light)};
    --ion-color-darker-contrast-rgb: #{splitHexToRGB(map-get($colors, light))};
    --ion-color-darker-shade: #{darken(map-get($colors, darker), 3%)};
    --ion-color-darker-tint: #{lighten(map-get($colors, darker), 3%)};

    --ion-color-light-primary: #{map-get($colors, light)};
    --ion-color-light-primary-rgb: #{splitHexToRGB(map-get($colors, light))};
    --ion-color-light-primary-contrast: #{map-get($colors, primary)};
    --ion-color-light-primary-contrast-rgb: #{splitHexToRGB(map-get($colors, primary))};
    --ion-color-light-primary-shade: #{darken(map-get($colors, light), 3%)};
    --ion-color-light-primary-tint: #{lighten(map-get($colors, light), 3%)};

}

.ion-color-navbar {
    --ion-color-base: #{map-get($colors, background)};
    --ion-color-base-rgb: var(--ion-color-navbar-rgb);
    --ion-color-contrast: var(--ion-color-navbar-contrast);
    --ion-color-contrast-rgb: var(--ion-color-navbar-contrast-rgb);
    --ion-color-shade: var(--ion-color-navbar-shade);
    --ion-color-tint: var(--ion-color-navbar-tint);
}

.ion-color-dark {
    --ion-color-base: #{map-get($colors, background)} !important;
    --ion-color-base-rgb: var(--ion-color-darker-rgb);
    --ion-color-contrast: var(--ion-color-darker-contrast);
    --ion-color-contrast-rgb: var(--ion-color-darker-contrast-rgb);
    --ion-color-shade: var(--ion-color-darker-shade);
    --ion-color-tint: var(--ion-color-darker-tint);
}

.ion-color-darker {
    --ion-color-base: transparent;
    --ion-color-base-rgb: var(--ion-color-darker-rgb);
    --ion-color-contrast: var(--ion-color-darker-contrast);
    --ion-color-contrast-rgb: var(--ion-color-darker-contrast-rgb);
    --ion-color-shade: var(--ion-color-darker-shade);
    --ion-color-tint: var(--ion-color-darker-tint);
}

.ion-color-light-primary {
    --ion-color-base: transparent;
    --ion-color-base-rgb: var(--ion-color-light-primary-rgb);
    --ion-color-contrast: var(--ion-color-light-primary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-light-primary-contrast-rgb);
    --ion-color-shade: var(--ion-color-light-primary-shade);
    --ion-color-tint: var(--ion-color-light-primary-tint);
}

:root {
    font-size: 62.5%;
}
