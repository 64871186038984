@import "../variables";

app-header-progress {
  .progress {
    position: absolute;
    left: 0;
    right: 0;
    height: 0.3rem;
    background-color: var(--theme-color-primary);
  }
}
