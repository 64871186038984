@import "../variables";

app-chapter-item {
    display: block;
    background: #fff;

    .expand-wrapper {
        position: relative;
        z-index: 1;

        .expand-trigger {
            position: absolute;
            z-index: 10;
            top: 0;
            left: 0;
            width: 6rem;
            height: 8rem;
            display: block;
            line-height: 8rem;
            font-size: 1rem;
            text-align: center;
            cursor: pointer;

            span {
                display: inline-block;
                vertical-align: top;
                transform: rotate(0deg);
                transform-origin: 50% 50%;

                &.expanded {
                    transform: rotate(180deg);
                }
            }
        }
        .toggle-skip-requirements {
            position: absolute;
            z-index: 10;
            top: 2.75rem;
            right: 2rem;
            width: 5rem;
            height: 2.5rem;
            border: 1px solid #e8e8e8;
            background: #f8f8f8;
            border-radius: 1.5rem;

            span {
                display: block;
                background-color: #c5c5c5;
                position: absolute;
                z-index: 10;
                width: 1.9rem;
                height: 1.9rem;
                left: calc(0.3rem - 1px);
                top: calc(0.3rem - 1px);
                border-radius: 50%;
                transition: left 0.3s;
            }
            input[type="checkbox"] {
                display: none;
            }
            input[type="checkbox"]:checked + span {
                left: 2.7rem;
                background-color: var(--theme-color-dark);
            }
        }
    }
    .item-expand {
        display: block;
        width: 100%;
        text-align: left;
        background: #fff;
        padding-left: 6rem;

        &.item.item-block.item-md {
            border-bottom-color: transparent;
            background: transparent;
        }
        .name {
            padding: 0;
        }
    }
    .item-skip-requirements {
        padding-right: 5rem;
    }
    .image {
        display: none;
    }
    .name {
        padding: 0;
    }
    .chapter-question {
        margin-left: 6rem;
        width: calc(100% - 6rem);
        padding-left: 0;
        min-height: 0;
        display: block;
        background: #fff;
        text-align: left;

        .icon-list {
            margin: 0 0.3rem 0 0;
            font-size: 9px;
        }
        .container {
            padding: 1.3rem 0;
            min-height: 0;

            .name {
                padding: 0.2rem 0 0 0.2rem;

                h2 {
                    font-size: 1.3rem;
                }
            }
        }
    }
    .container {
        .checkmark-blue {
            margin: 0.1rem 0 -0.5rem 0;
            width: 4rem;
            display: inline-block;
            vertical-align: top;
        }
    }
}
