@import "../variables";

app-radio,
app-radio-searchable {
    .choice {
        display: flex;
        flex-direction: column;

        ion-chip {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            border-radius: 2.3rem;
            flex-basis: 100%;
            flex-grow: 1;
            padding: 1rem 2rem;

            &[color="default"] {
                border-color: var(--theme-color-border);

                ion-icon {
                    color: var(--theme-color-border);
                }
            }
            &[color="checked"] {
                border-color: var(--theme-color-primary);

                ion-icon {
                    color: var(--theme-color-primary);
                }
            }
        }

        ion-label {
            display: block;
            width: 100%;
            font-size: 1.4rem;
            line-height: 1.42857143;
            padding: .8rem 2rem;
            word-wrap: break-word;
            white-space: normal;
        }
    }

    .choice-block,
    .choice-block-wrapper {
        display: block;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 1rem 0 2rem 0;
    }
    .choice-block {
        margin: 0 0 1rem 0;
    }

    .choice-description {
        display: block;
        color: var(--theme-color-dark);
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }

    .choice-subtitle {
        font-size: 1rem;
        text-align: center;
        line-height: 1.4;
        word-wrap: break-word;
    }
}

.choice-block-wrapper {
    margin: 1rem 0;
}
