@function splitHexToRGB($color) {
    @return red($color) + ', ' + green($color) + ', ' + blue($color);
}

@mixin set-property-with-safe-area-inset($property, $value, $area) {
    @if ($value == 0) { $value: $value + px; }
    #{$property}: #{$value}; /* Android with no env() or safe-area-inset-* support */
    #{$property}: calc(#{$value} + env(safe-area-inset-#{$area}));
}

@mixin set-property-with-safe-area-inset-top($property, $value) {
    @include set-property-with-safe-area-inset($property, $value, top);
}

@mixin set-property-with-safe-area-inset-bottom($property, $value) {
    @include set-property-with-safe-area-inset($property, $value, bottom);
}
