@import "../variables";

page-questionnaire-chapters {
    .content {
        display: block;
        border-bottom: 1px solid #e8e8e8;
    }
    .chapter-header {
        padding-top: 1rem;
        background-color: var(--theme-color-light-grey);

        h2 {
            padding: 1rem 2rem 0;
            margin-top: 0;
            margin-bottom: 0;
        }

        p {
            padding: 1rem 2rem;
            margin: 0;
        }
    }
}
