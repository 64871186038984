@import "./variables";

@font-face {
    font-family: 'Balance-Regular';
    src: url('#{$font-path}/Balance-Regular.otf') format('opentype');
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-FB02;
}

@font-face {
    font-family: 'Balance-Light';
    src: url('#{$font-path}/Balance-Light.otf') format('opentype');
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-FB02;
}

@font-face {
    font-family: 'Sans Serif Pro';
    src: url('#{$font-path}/SourceSansPro-Light.otf') format('opentype');
    font-style: normal;
    font-stretch: normal;
    font-weight: 300;
    unicode-range: U+0020-FB02;
}

@font-face {
    font-family: 'Sans Serif Pro';
    src: url('#{$font-path}/SourceSansPro-Regular.otf') format('opentype');
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    unicode-range: U+0020-FB02;
}

@font-face {
    font-family: 'Sans Serif Pro';
    src: url('#{$font-path}/SourceSansPro-Semibold.otf') format('opentype');
    font-style: normal;
    font-stretch: normal;
    font-weight: 600;
    unicode-range: U+0020-FB02;
}

@font-face {
    font-family: 'Sans Serif Pro';
    src: url('#{$font-path}/SourceSansPro-Bold.otf') format('opentype');
    font-style: normal;
    font-stretch: normal;
    font-weight: 700;
    unicode-range: U+0020-FB02;
}

body {
    background: var(--theme-color-background);
}

ion-app {
    @include set-property-with-safe-area-inset(margin-top, 0, top);

    @media (orientation: landscape) {
        @include set-property-with-safe-area-inset(margin-right, 0, right);
        @include set-property-with-safe-area-inset(margin-left, 0, left);
    }
}

.light {
    font-family: 'Balance-Light', -apple-system, "Helvetica Neue", "Roboto", sans-serif;
}

a {
    text-decoration: none;
}

p {
    font-size: 1.4rem;

    &.light {
        font-family: 'Balance-Light', -apple-system, "Helvetica Neue", "Roboto", sans-serif;
    }
}

span {
    font-size: 1.4rem;
}

.bold-text {
    font-weight: bold;
    color: var(--theme-color-dark-grey);
}

.readonly {
    color: #ccc;
}

.has-header .scroll-content {
    top: $toolbar-height;
}

.has-footer .scroll-content {
    @include set-property-with-safe-area-inset-bottom(bottom, 5.2rem);
}

.toolbar-ios {
    padding: 0;
}

.back-button ion-icon {
    font-size: 2.4rem;
}

.bar-button-default-md:hover {
    color: #f0f0f0 !important;
}

// Center title for Browser and Android
.title-md {
    position: absolute;
    left: 0;
    margin-top: 4px;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: center;
}

.dashboard-icon {
    padding-right: 1.2rem;
    padding-left: 1.2rem;
}

.questionnaire-header {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    height: 8.8rem;
}

.datetime-input {
    background-color: var(--theme-color-light);
    font-weight: 500;
    line-height: 1.2;
    margin-top: 2rem;
    font-size: 3.6rem;
    border: none;
}

.open-calendar {
    display: inline-block;
    position: relative;
    top: 1.6rem;
    right: 7.5rem;
    background: #fff;
    pointer-events: none;
    max-width: 0;
    max-height: 0;
    > ion-icon {
        display: inherit;
        width: 2rem;
        height: 2rem;
    }
}

.scroll-content {
    overflow-y: auto;
}

.button-inner {
    font-size: 1.6rem;
}

.item-ios,
.item-md {
    min-height: 5.8rem;

    &.item-block .item-inner {
        border-color: #e8e8e8;
    }
}

.invisible {
    visibility: hidden;
}

.loading-content {
    font-weight: 500 !important;
}

// Form validation color
.ng-dirty.ng-invalid {
    color: var(--theme-color-darker);

    &:not(:focus) {
        color: var(--theme-color-danger);
    }
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 1.6rem;
    margin-bottom: 1rem;
    font-weight: 500;
    line-height: 1.2;
}

h1 {
    margin-top: 2rem;
    font-size: 3.6rem;
}

h2 {
    margin-top: 1.8rem;
    font-size: 2.2rem;
}

h3 {
    font-size: 1.5rem;
}

h1 + h2, h1 + h3, h2 + h3 {
    margin-top: -0.3rem;
}

.mt-4 {
    margin-top: 1.5rem;
}

.ml-5 {
    margin-left: 2rem;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

@import "./ionic/button";
@import "./ionic/icon";
@import "./ionic/modal";
@import "./ionic/ion-back-button";
@import "./ionic/ion-badge";
@import "./ionic/ion-button";
@import "./ionic/ion-checkbox";
@import "./ionic/ion-content";
@import "./ionic/ion-footer";
@import "./ionic/ion-header";
@import "./ionic/ion-input";
@import "./ionic/ion-item";
@import "./ionic/ion-modal";
@import "./ionic/ion-select";
@import "./ionic/ion-toast";

@import "./components/app-bar";
@import "./components/card";
@import "./components/chapter-item";
@import "./components/checkbox";
@import "./components/dashboard-footer";
@import "./components/datetime";
@import "./components/header-progress";
@import "./components/lat-lng";
@import "./components/manual-choice-addition";
@import "./components/notification-modal";
@import "./components/number";
@import "./components/photo";
@import "./components/radio";
@import "./components/select-item";
@import "./components/text";
@import "./components/questionnaire-footer";
@import "./components/attachments-and-remarks";
@import "./components/questionnaire-overlay.scss";
@import "./components/disclaimer";
@import "./components/searchable-choices";
@import "./components/searchbar";
@import "./components/scrollto";

@import "./pages/app";
@import "./pages/choose-repeat-questions";
@import "./pages/contact";
@import "./pages/dashboard";
@import "./pages/card-game";
@import "./pages/external-account-login";
@import "./pages/forgot-password-form";
@import "./pages/news-details";
@import "./pages/password-change-form";
@import "./pages/questionnaire";
@import "./pages/questionnaire-chapters";
@import "./pages/questionnaire-header";
@import "./pages/questionnaire-disclaimer";
@import "./pages/registration";
@import "./pages/select-structure-node";
@import "./pages/select-questionnaire";
@import "./pages/support";
@import "./pages/deeplink-questionnaire-opener";
@import "./pages/questionnaire-tasks";
@import "./pages/questionnaire-task";
@import "./pages/media-communication-details";
@import "./pages/scan-qr";
