page-dashboard {

    .icon {
        display: block;
        text-align: center;
        margin: 1.8rem 0 1.2rem 0;

        svg {
            display: inline-block;
            vertical-align: top;
            width: auto;
            height: 3.5rem;
        }
    }

    .icon-description {
        display: block;
        line-height: 1.5rem;
        height: 1.5rem;
        font-size: minmax(4vw, 1.5rem);
        text-align: center;
        color: var(--theme-color-dark);
        margin-bottom: 1.6rem;
    }

    [tile] {
        padding: 0.8rem;
        position: relative;
        background-color: var(--theme-color-light);
        background-clip: content-box, padding-box;
        transform: translate3d(0,0,0); // VW-2735 - Fix background-clip rendering
        cursor: pointer;

        &.darker {
            background-color: var(--theme-color-darker);

            .icon .primary {
                fill: var(--theme-color-danger);
            }
            .icon-description {
                color: var(--theme-color-light);
            }
        }

        &.primary {
            background-color: var(--theme-color-primary);

            .icon {
                color: var(--theme-color-darker);
            }
            .icon-description {
                color: var(--theme-color-light);
            }

            &.clickable-hover, &.clickable-active {
                background-color: var(--theme-color-darker);

                .icon {
                    color: var(--theme-color-primary);
                }
            }
        }

        &.clickable-hover, &.clickable-active {
            background-color: var(--theme-color-primary);

            .icon .primary {
                fill: var(--theme-color-light);
            }

            .icon-description {
                color: var(--theme-color-light);
            }
        }
    }

    ion-badge {
        --background: var(--theme-color-danger);

        position: absolute;
        top: 1.6rem;
        right: 1.6rem;
    }
}

.dashboard {
    background-color: var(--theme-color-background);
    margin-bottom: 1.6rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.dashboard__header {
    color: var(--theme-color-light);
    text-align: left;
    padding: 0 2.4rem .8rem!important;

    ion-title {
        padding: 0;
        margin-bottom: .8rem;
    }
}

.dashboard__title {
    font-size: 2.8rem;
    line-height: 1.14285714;
    font-weight: 600;
}

.dashboard__description {
    font-size: 1.5rem;
    line-height: 1.5;
}

.dashboard__button {
    --background: var(--theme-color-light);
    --border-radius: .8rem;
    --color: var(--theme-color-text);

    width: 100%;
    height: auto;

    --padding--top: 1.6rem;
    --padding--end: 3.2rem;
    --padding--bottom: 1.6rem;
    --padding--start: 3.2rem;
    margin-top: 1.6rem;
}

.dashboard__button--notification {
    --background: var(--theme-color-warning);
    --color: var(--theme-color-light);
}

.dashboard__btn-wrap {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    padding: 1.2rem 0;
}

.dashboard__btn-wrap--big {
    align-items: flex-start;
    gap: 1.6rem;
    text-align: left;
    white-space: normal;
    width: 100%;
    padding: 1.6rem .8rem;
}

.dashboard__icon {
    flex: 0 0 2.4rem;
    width: 2.4rem;
    height: 2.4rem;
}

.dashboard__icon--bell {
    margin-right: 1.2rem;
}

.dashboard__icon--big {
    flex-basis: 3.2rem;
    width: 3.2rem;
    height: 3.2rem;
}

.dashboard__icon-text {
    display: flex;
    flex-direction: column;
}

.dashboard__icon-title {
    font-size: 2.4rem;
    line-height: 1.25;
    font-weight: 600;
}

.dashboard__icon-description {
    color: var(--theme-color-light-text);
    font-size: 1.4rem;
    line-height: 1.5;
}
