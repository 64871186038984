@import "../variables";

ion-modal {
    --backdrop-opacity: 0.4 !important;
    --border-radius: 0;
    --box-shadow: 0;
}

.modal-wrapper {
    margin: auto 2.2rem auto 2.2rem;
}

#ion-modal {
    // Important to set the newest modal back and override the z-index Ionic sets
    &-back {
        .card-game-card {
            transform: scale(0.7) translateY(2rem);
        }

        pointer-events: none;
        z-index: 20001 !important;
    }

    &-middle {
        .card-game-card {
            transform: scale(0.85) translateY(0);
        }

        pointer-events: none;
        z-index: 20002 !important;
    }

    &-front {
        .card-game-card {
            transform: scale(1) translateY(-2rem);
        }

        z-index: 200003 !important;
    }
}

.card-game-card {
    background-color: var(--theme-color-light);
    border-radius: 1rem;
    overflow: hidden;
    height: calc(88vh - 4rem);
    margin: auto 1.6rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    transform-origin: 50% 100%;
    transition-duration: 0.75s;

    &__content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: calc(100% - 11rem - 100px);
        overflow-y: auto;
        padding-bottom: 2rem;
    }

    &__toolbar {
        height: 11rem;
        padding-top: 3rem;
    }

    ion-content {
        height: 100%;
        --background: #fff !important;
    }

    ion-toolbar {
        --background: #fff !important;
    }

    ion-header {
        border-bottom: .1rem solid var(--theme-color-border);
        text-align: left;
        padding-left: 2.4rem;

        h2 {
            font-size: 1.3rem;
            line-height: 1.23076923;
            font-weight: 400;
            margin: 0;
            padding: 1.6rem 0 1.5rem;
        }
    }
}

.card-game-card__label {
    color: var(--theme-color-text-gray);
    font-size: 1.4rem;
    line-height: 1.42857143;
    margin: 0 0 1.6rem;
}

.card-game-card__question {
    font-size: 1.8rem;
    line-height: 1.33333333;
    font-weight: 600;
    margin: 0;
}
