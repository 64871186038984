@import "../variables";

ion-searchbar {
    display: block;

    &.ios {
        border-bottom: 0.55px solid rgba(0, 0, 0, 0.05);

        .searchbar-input-container {
            height: auto;
        }

        .searchbar-input {
            font-size: 12px;
            border-radius: 5px;
            background: rgba(0,0,0,0.05);
            border: 1px solid rgba(0,0,0,0.02);
            height: 30px;
            line-height: 30px;
        }

        .searchbar-search-icon {
            color: inherit;
            width: 16px;
            left: 8px;
        }
    }

    &.md {
        --icon-color: rgba(0,0,0,.6);
        --placeholder-color: #000;
        --placeholder-opacity: .3;
        --box-shadow: none;
        --background: #fff;
        border: .1rem solid var(--theme-color-border);
        border-radius: .4rem;
        padding: 0;
    }

    input {
        padding: 0 0 0 4.2rem !important;
    }

    ion-icon {
        width: 1.6rem !important;
        height: 1.6rem !important;
        top: .8rem !important;
        color: var(--ion-color-primary) !important;
    }
}
