@import "../variables";

.card {
    background: var(--theme-color-light);
    border-radius: 1.2rem;
    padding: 2.8rem 3.2rem;
    margin-right: -.8rem;
    margin-left: -.8rem;

    & + & {
        margin-top: 1.6rem;
    }
}

.card--compact {
    padding: 1.6rem;
}

.card--full-height {
    height: 100%;
}

.card--no-header {
    margin-top: 8.8rem;
}

.card__center {
    text-align: center;
}

.card__icon {
    width: 3.6rem;
    height: 3.6rem;
    margin-bottom: 1.6rem;
}

.card__icon--primary {
    color: var(--theme-color-primary);
}

.card__icon--danger {
    color: var(--theme-color-danger);
}

.card__icon--modal {
    width: 5.6rem;
    height: 5.6rem;
}

.card__icon--history {
    color: var(--theme-color-primary-light);
    width: 20rem;
    height: 20rem;
    margin-top: 7.2rem;
}

.card__header {
    margin-bottom: 2.4rem;
}

.card__title {
    font-size: 2.4rem;
    line-height: 1.33333333;
    font-weight: 600;
    margin-top: 0;
}

.card__toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: none;
    color: var(--theme-color-text);
    font-size: 1.4rem;
    line-height: 1.71428571;
    font-weight: 600;
    width: 100%;
    padding: 1.6rem 0 .8rem;
    position: relative;

    &::before {
        display: block;
        content: '';
        background: var(--theme-color-border);
        height: .1rem;
        position: absolute;
        right: -3.2rem;
        bottom: 0;
        left: -3.2rem;
    }
}

.card__toggle-icon {
    background: var(--theme-color-primary-light);
    border-radius: 50%;
    padding: 1.4rem;
    position: relative;

    &::before,
    &::after {
        display: block;
        content: '';
        background: var(--theme-color-primary);
        border-radius: .1rem;
        width: 1rem;
        height: .2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }

    &::after {
        transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }
}

.card__toggle-icon--active {

    &::after {
        transform: translate3d(-50%, -50%, 0) rotate(0);
    }
}

.card__description {
    color: var(--theme-color-text-gray);
    font-size: 1.4rem;
    line-height: 1.71428571;
    margin: 0;
    white-space: pre-line;
}

.card__link {
    color: var(--theme-color-primary);
    font-weight: 600;
    margin: .4rem 0;
}

.card__search {
    width: auto;
    margin: 0 -1.6rem 1.6rem;
    box-shadow: none;
}

.card__concepts {
    margin-right: -1.6rem;
    margin-left: -1.6rem;
}

.card__btns {
    margin-top: 1.6rem;
}

.card__btns--space {
    margin-top: 4rem;
}

.card__btn {
    --border-radius: 2.4rem;
    --border-width: .1rem;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 700;
    --padding-top: 1.7rem;
    --padding-bottom: 1.7rem;
    height: auto;

    & + & {
        margin-top: 1.6rem;
    }
}

.card__btn--outline {
    --border-color: var(--theme-color-border);
    color: var(--theme-color-dark-grey);
    margin-top: 1.6rem;
}

.card__btn--light {
    --background: var(--theme-color-light);
    --border-color: var(--theme-color-light);
    color: var(--theme-color-dark-grey);
    margin-top: 1.6rem;
}

.card__ticket {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .8rem;
    border-top: .1rem solid var(--theme-color-border);
    font-size: 1.4rem;
    line-height: 1.14285714;
    padding: 1.5rem 1.6rem 1.6rem;
    margin-top: 1.2rem;
}

.card__ticket-icon {
    color: var(--theme-color-primary);
    width: 1.6rem;
    height: 1.6rem;
}
