page-questionnaire-disclaimer {
    .main {
        padding: 1.5rem;
    }

    .disclaimer-title {
        text-align: center;
        margin-bottom: 1rem;
    }

    .icon {
        display: block;
        text-align: center;
        margin: 2rem 0 1.2rem 0;

        svg {
            display: inline-block;
            vertical-align: top;
            width: auto;
            height: 3.5rem;
        }

        .darker {
            fill: var(--theme-color-darker);
        }

        .danger {
            fill: var(--theme-color-danger);
        }
    }

    .line {
        border-bottom: 2px solid var(--theme-color-primary);
    }

    .title {
        font-weight: bold;
        margin: 1rem 0;
    }

    .info {
        padding: 3rem;

        ul {
            padding-left: 1.8rem;
            font-size: 1.4rem;
        }
    }

    .icon-description {
        display: block;
        line-height: 1.5rem;
        height: 1.5rem;
        font-size: 1.5rem;
        text-align: center;
        color: var(--theme-color-light);
        margin-bottom: 1.2rem;
    }

    [tile] {
        position: relative;
        background-color: var(--theme-color-primary);
        background-clip: content-box, padding-box;
        transform: translate3d(0,0,0); // VW-2735 - Fix background-clip rendering
        cursor: pointer;

        &:hover, &:active {
            background-color: var(--theme-color-darker);

            .icon .darker {
                fill: var(--theme-color-light);
            }
        }
    }

    .fixed-content {
        background-color: rgba(0, 0, 0, .6);
    }
}
