@import "../variables";

page-external-account-login {
    .scroll-content {
        background-color: var(--theme-color-light-grey);
    }

    .item-ios, .item-md {
        min-height: initial;
    }

    ion-item {
        --padding-start: 0;
        --inner-padding-end: 0;
        margin-bottom: 1.2rem;
    }

    .fields {
        background-color: var(--theme-color-light);

        ion-item {
            padding-left: 0;
        }

        ion-item:last-child {
            .item-inner {
                .input-wrapper {
                    border-bottom: 0;
                }
            }
        }

        .item-md.item-block .item-inner {
            border: none;
        }

        .input-wrapper {
            padding: 1.5rem 2rem 1.3rem 0;
            margin-left: 2rem;
            border-bottom: 1px solid #e8e8e8;
        }

        ion-input {
            font-size: 1.3rem;
            line-height: 1.3rem;
            color: var(--theme-color-darker);
            padding: 0;
        }
    }

    .forgot-password {
        --ion-color-contrast: var(--theme-color-text-gray);
        --padding-start: 0;

        font-size: 1.3rem;
    }
}
