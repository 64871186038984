@import "../variables";

page-contact {

    ion-select {
        --padding-start: 2rem !important;
        --padding-top: 1.4rem !important;
        --padding-bottom: 1.4rem !important;
        background: map-get($colors, input-bg) !important;
        border-radius: .4rem;
    }
}
