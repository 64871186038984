@import "base";
@import "variables";

@mixin ionic-themed-style($colors) {
    --theme-color-background: #{map-get($colors, background)};
    --theme-color-border: #{map-get($colors, border)};
    --theme-color-primary: #{map-get($colors, primary)};
    --theme-color-primary-light: #{map-get($colors, primary-light)};
    --theme-color-secondary: #{map-get($colors, secondary)};
    --theme-color-danger: #{map-get($colors, danger)};
    --theme-color-light: #{map-get($colors, light)};
    --theme-color-grey: #{map-get($colors, grey)};
    --theme-color-light-grey: #{map-get($colors, lightGrey)};
    --theme-color-light-text: #{map-get($colors, lightText)};
    --theme-color-dark-grey: #{map-get($colors, darkGrey)};
    --theme-color-dark: #{map-get($colors, dark)};
    --theme-color-darker: #{map-get($colors, darker)};
    --theme-color-navbar: #{map-get($colors, navbar)};
    --theme-color-navbar-rgb: #{splitHexToRGB(map-get($colors, navbar))};
    --theme-color-text: #{map-get($colors, text)};
    --theme-color-text-gray: #{map-get($colors, text-gray)};
    --theme-color-success: #{map-get($colors, success)};
    --theme-color-warning: #{map-get($colors, warning)};
    --theme-color-input-bg: #{map-get($colors, light)};
    --theme-color-input-color: #{map-get($colors, input-color)};

    @include ionVariables($colors);

    @media (prefers-color-scheme: dark) {
        body,
        .md body {
            @include ionVariables($colors);
        }
    }

    @each $key, $color in $colors {
        .text-color-#{"" + $key} {
            color: $color;
        }
    }
}

:root {
    @include ionic-themed-style($colors);
}

@import "tenant";
