@import "../variables";

page-questionnaire {

    h2 {
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 0;
    }

    ion-footer .toolbar,
    ion-footer .toolbar-md,
    ion-footer .toolbar-ios {
        height: auto;
        min-height: 0;
    }

    .question-header {
        line-height: 1.4em;
        font-size: 2.2rem;
        text-align: left;
        padding: 1.6rem 3.2rem;

        .question-description {
            margin-top: 0.4rem;
            padding: 0 2rem;
            white-space: pre-line;
        }
    }

    .questionnaire-sub-title {
        border-bottom: .1rem solid var(--theme-color-border);
        padding: 1.6rem 3.2rem 1.5rem;

        h2 {
            font-size: 1.3rem;
            line-height: 1.23076923;
            font-weight: 400;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .questionnaire-input-container {
        .item-ios, .item-md {
            padding: 0;
            min-height: 4rem;
            margin-top: 0;
        }
    }
}

.questionnaire {
    display: flex;
    flex-direction: column;
    background: var(--theme-color-light);
    border-radius: 1.2rem;
    height: 100%;
    position: relative;
    box-shadow: 0 .4rem .4rem hsla(202, 52%, 49%, 0.22);

    &::before,
    &::after {
        content: '';
        background: var(--theme-color-light);
        border-bottom-right-radius: 1.2rem;
        border-bottom-left-radius: 1.2rem;
        height: 1.6rem;
        position: absolute;
        z-index: -1;
    }

    &::before {
        background: #{rgba(map-get($colors, light), .6)};
        right: 3.2rem;
        bottom: -3.2rem;
        left: 3.2rem;
    }

    &::after {
        background: #{rgba(map-get($colors, light), .8)};
        right: 1.6rem;
        bottom: -1.6rem;
        left: 1.6rem;
        box-shadow: 0 .4rem .4rem hsla(202, 52%, 49%, 0.22);
    }
}

.questionnaire__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 2.8rem 3.2rem 1.6rem;
    overflow: auto;
}

.questionnaire__content {
    flex: 1;
}
