$base-spacing: 2rem;
$font-path: "../assets/fonts";
$font-family-base: "Sans Serif Pro", -apple-system, "Helvetica Neue", "Roboto", sans-serif;
$toolbar-height: $base-spacing * 1.6;

$colors: (
    background:    hsl(202, 52%, 49%),
    border:        hsl(203, 26%, 88%),
    border-light:  hsl(202, 60%, 56%),
    primary:       hsl(192, 100%, 47%),
    primary-light: hsl(200, 58%, 95%),
    secondary:     lighten(hsl(210, 100%, 62%), 33%),
    danger:        hsl(10, 100%, 64%),
    light:         hsl(0, 0%, 100%),
    grey:          hsl(0, 0%, 82%),
    lightGrey:     hsl(0, 0%, 97%),
    lightText:     hsl(0, 0%, 65%),
    darkGrey:      hsl(0, 0%, 20%),
    dark:          hsl(202, 52%, 37%),
    darker:        hsl(210, 50%, 30%),
    navbar:        hsla(210, 50%, 40%, 0.93),
    text:          hsl(0, 0%, 0%),
    text-gray:     hsl(0, 0%, 53%),
    success:       hsl(97, 71%, 53%),
    warning:       hsl(36, 100%, 50%),
    input-bg:      hsl(202, 11%, 97%),
    input-color:   hsl(204, 9%, 32%),
);
