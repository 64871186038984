@import "../variables";

ion-header {
    text-align: center;
    user-select: none;

    ion-toolbar {
        --min-height: auto;
        padding: 2.4rem !important;
    }

    ion-title {
        flex: 0;
        padding: 0;
    }

    ion-buttons {
        margin: 0;
    }

    ion-button {
        padding: 0 !important;
        margin: 0 !important;
        height: auto;
    }

    .dashboard-icon {

        &.back-button {
            margin-left: 0;
        }
    }

    &::after {
        display: none;
    }
}
