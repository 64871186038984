@import "../variables";

body.scanner-active {
    --background: transparent;
    background: transparent;
    --ion-background-color: transparent;
}

.scan-qr {
    &--scan-in-progress {
        width: 100vw;
        height: 4rem;
        margin-top: 0;
        padding-top: 1rem;
        background-color: #{map-get($colors, light)};
    }
}
