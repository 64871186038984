app-manual-choice-addition {
    display: block;

    .manual-choice {
        text-align: center;
        margin: 2rem 0;

        &--radio-like {
            --input-border-radius: 2.6rem;
            --add-button-border-radius: 50%;
        }

        &--checkbox-like {
            --input-border-radius: 0.8rem;
            --add-button-border-radius: 0.7rem;
        }

        &__item {
            --border-width: 0;
            --padding-start: 0;
            --padding-end: 0;
            --inner-padding-start: 0;
            --inner-padding-end: 0;

            position: relative;
            margin: 0 2rem;
        }

        &__label {
            display: block;
            color: var(--theme-color-dark);
            font-size: 1.4rem;
            margin-bottom: 1rem;
        }

        &__input[type="text"] {
            --background: var(--theme-color-secondary);
            --padding-top: 1.3rem;
            --padding-end: 4.6rem;
            --padding-bottom: 1.3rem;
            --padding-start: 2rem;
            --border-radius: var(--input-border-radius);

            width: 100%;
            padding: 0;
            color: var(--theme-color-dark);
            font-size: 1.4rem;
            margin: 0;
            text-align: center;
            border-radius: var(--border-radius);
            position: relative;
            z-index: 1;
            text-indent: 1.3rem;
        }

        &__add-button {
            $size: 3.4rem;

            --border-radius: var(--add-button-border-radius);
            --padding-start: 0;
            --padding-end: 0;
            --padding-top: 0;
            --padding-bottom: 0;

            position: absolute;
            z-index: 2;
            top: 50%;
            right: 0.6rem;
            width: $size;
            height: $size;
            margin-top: $size * -0.5;

            ion-icon {
                --ionicon-stroke-width: #{$size * 1.6};

                width: $size * 0.6;
                height: $size * 0.6;
            }
        }

        &__error {
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.2;
            margin: 0.5em 0 0 0;
        }
    }
}
