@import "../variables";

app-select-item, app-chapter-item {
    .item.item-block.item-md {
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: -1px;
    }

    .item-ios.item-block .item-inner, .item-md.item-block .item-inner {
        border: none;
    }

    .container {
        display: flex;
        flex-direction: row;
        // min-height: 8rem;
        justify-content: center;
        align-items: center;
        padding: 1.2rem 0;
        // margin: 0 -1.6rem;
    }

    .image {
        width: 5.6rem;
        height: 5.6rem;
        padding: .4rem 1.6rem;
        flex-shrink: 0;
    }

    .name {
        flex-grow: 10;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        white-space: normal;
        padding: 0.2rem 0.5rem 0;

        * {
            padding: 0;
            margin: 0;
        }

        .sub-title {
            margin-top: 0.5rem;
            font-size: 1.2rem;
            color: var(--theme-color-light-text);

            .icon {
                vertical-align: top;
                display: inline-block;

                svg {
                    vertical-align: top;
                    display: inline-block;
                    height: 14px;
                    width: auto;
                    margin: 0 3px 0 0;

                    .primary {
                        fill: currentColor;
                    }
                }
            }
        }
    }

    &.icon-warning .name .sub-title .icon svg .primary {
        fill: #f39c12;
    }
    &.icon-danger .name .sub-title .icon svg .primary {
        fill: #dd4b39;
    }

    .arrow-right {
        display: block;
        transform: rotate(-90deg);
    }

    .checkmark-blue {
        margin-top: 0.7rem;
        width: 3.5rem;
    }

    ion-badge {
        margin-right: .8rem;
    }
}

app-select-item {
    margin-right: -1.6rem;
    margin-left: -1.6rem;
}

.select-item {
    --padding-start: 0;
}

.select-item__wrap {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-top: .8rem;
    padding-bottom: .8rem;
    padding-left: 1.6rem;
    gap: .8rem;
}

.select-item__image {
    display: flex;
    flex: 0 0 4.8rem;
    padding: .8rem 1.2rem;
    margin-right: .8rem;

    page-questionnaire-history &,
    page-questionnaire-tasks &,
    page-select-questionnaire & {
        display: none;
    }
}

.select-item__text {
    flex: 1;
    font-size: 1.4rem;
    line-height: 1.14285714;
    padding-right: .8rem;
    overflow: hidden;
}

.select-item__title {
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.select-item__description {
    color: var(--theme-color-text-gray);
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.select-item__icon {
    color: var(--theme-color-primary);
    vertical-align: middle;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: .8rem;
}

.select-item__icon-favorite {
    font-size: 24px;
}

.select-item__icon-circle {
    background: var(--theme-color-primary-light);
    border-radius: 50%;
    color: var(--theme-color-primary);
    vertical-align: middle;
    width: 2.8rem;
    height: 2.8rem;
    flex: 0 0 2.8rem;
}

.select-item__icon-circle--faq {
    transform: rotate(90deg);
    transition: transform .2s ease;
}

.select-item__icon-circle--faq-active {
    transform: rotate(-90deg);
}

.select-concept {
    --padding-start: 1.6rem;
}

.select-concept__item {
    flex: 1;
    padding: 1.6rem 0;
    overflow: hidden;
    position: relative;
}

.select-concept__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: .8rem;
    margin-bottom: .8rem;
}

.select-concept__meta {
    overflow: hidden;
}

.select-concept__badge {
    font-size: 0;
}

.select-concept__title,
.select-concept__description {
    font-size: 1.4rem !important;
    line-height: 1.14285714;
}

.select-concept__title {
    font-weight: 600;
}

.select-concept__description {
    color: var(--theme-color-text-gray) !important;
    font-weight: 400;
}

.select-concept__progress {
    --background: var(--theme-color-border);
}
