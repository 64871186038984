@import "../variables";

page-select-structure-node {
    h2, h3 {

        .arrow-right {
            margin-right: 2rem;
            margin-left: auto;
            cursor: pointer;
            display: flex;
            align-items: center;

            &.expanded {
                transform: rotate(0);
            }
        }
    }

    .projects {
        background-color: var(--theme-color-light);
        padding-left: 2rem;
    }

    .project {
        display: flex;
        align-items: center;

        border-bottom: 1px solid #d9d9d9;
        padding: 1.6rem 0;

        h3 {
            margin-top: 0;
        }
    }

    .project-info {
        flex: 1;
    }

    .project-location-label {
        display: block;
        margin-top: 0.5rem;

        font-size: 1.2rem;
        line-height: 0.5;
    }

    .project-location {
        font-size: 1.2rem;
        color: var(--theme-color-light-text);
    }

    .arrow-right {
        transform: rotate(-90deg);
    }
}
