@import "../../styles/variables";

ion-content {
    --background: var(--theme-color-background) !important;
    --padding: 0;
    --padding-top: var(--padding);
    --padding-bottom: 1.6rem;
    --padding-start: 2.4rem;
    --padding-end: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content__full-height {
    min-height: 100%;
}

.content__full-height--questionnaire {
    padding-bottom: 3.2rem;
    margin-right: -.8rem;
    margin-left: -.8rem;
}
