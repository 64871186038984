@import "../variables";

ion-button {
    --box-shadow: none;
    --border-radius: 0;

    text-transform: none;
    margin: 0;
    height: $base-spacing * 2.5;
    font-weight: 400;
    font-size: 1.6rem;
    letter-spacing: 0;

    &[color] {
        --box-shadow: none;
    }

    &.button-small {
        --padding-top: #{$base-spacing * 0.3};
        --padding-start: #{$base-spacing * 0.9};
        --padding-end: #{$base-spacing * 0.9};
        --padding-bottom: #{$base-spacing * 0.3};

        height: auto;
        font-size: 0.9rem;
    }
}
