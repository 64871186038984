@import "../variables";

page-questionnaire-tasks {

    .header-badge {
        display: flex;
        justify-content: space-between;

        h2 {
            flex-grow: 1;
            margin-right: 1.8rem;
        }

        ion-badge {
            --background: var(--theme-color-danger);

            margin-top: 2.5rem;
            margin-right: 1.8rem;
        }
    }

    app-select-item .image {
        display: none;
    }

    ion-content {
        --background: var(--theme-color-light-grey);
    }
}
