app-scrollto {
    $button-size: 4.5rem;

    display: block;
    position: sticky;
    z-index: 999;
    right: 0;
    height: 1px;
    text-align: right;
    @include set-property-with-safe-area-inset-bottom(bottom, 6rem);

    .buttons {
        display: block;
        height: inherit;
        padding: 0;
        margin: 2rem 1.5rem 0 1.5rem;

        .button {
            border-radius: $button-size;
            line-height: $button-size;
            width: $button-size;
            height: $button-size;
            padding: 0;
            margin: 0 0 0 0.5rem;
            text-align: center;
            overflow: hidden;
            display: inline-block;
            vertical-align: top;
            border: 1px solid rgba(0,0,0,0.1);
            background: #fff;
        }
        .button-up {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}
