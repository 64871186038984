@import "../variables";

app-text,
app-text-autoheight {
    .ion-textarea {
        --background: var(--theme-color-input-bg);
        --padding-top: 1.6rem;
        --padding-end: 1.6rem;
        --padding-bottom: 1.6rem;
        --padding-start: 1.6rem;

        border: .1rem solid var(--theme-color-primary);
        border-radius: .4rem;
        width: 100%;
        padding: 0;
        color: var(--theme-color-input-color);
        font-size: 1.4rem;
        margin: 0;
        text-align: left;
    }
    textarea {
        min-height: 15rem;
    }
    .textarea-counter {
        text-align: right;
        padding: 1rem;
        font-size: 1.2rem;
        color: #a7a7a7;
    }
}

app-text-autoheight {
    .textarea {
        min-height: auto;
    }
}

ion-input {

}
