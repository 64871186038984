app-searchable-choices {
    display: block;
    position: sticky;
    z-index: 999;
    top: 0;

    &.app-searchable-choices--ios {
        background: #fff;
    }
}

.choices-limited {
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 100%;
        left: -0.5rem;
        right: -0.5rem;
        pointer-events: none;
        height: 20rem;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%);
    }
}
