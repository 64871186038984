/**
 Ion-select items do not allow image tags inside of the html content - so we have to add them using CSS
 */
@mixin before-flag-style {
    content: " ";
    width: 24px;
    height: 16px;
    position: relative;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 20px;
}

@mixin flag-styling($language) {
    ion-item.language-select--#{$language}::part(native)::before {
        @include before-flag-style;
        background-image: url("../../assets/images/icon-flag-" + $language + ".svg");
    }

    ion-select.language-select--#{$language}::part(text)::before {
        @include before-flag-style;
        top: 4px;
        background-image: url("../../assets/images/icon-flag-" + $language + ".svg");
    }
}

@include flag-styling('nl');
@include flag-styling('de');
@include flag-styling('en');
@include flag-styling('pl');
@include flag-styling('pt');
@include flag-styling('tr');
@include flag-styling('ro');
@include flag-styling('bg');
