@import "../variables";
@import "../mixin";

app-questionnaire-footer {
    display: block;
    background-color: var(--theme-color-background);

    // Clear floats
    &::after {
        content: '';
        display: table;
        width: 100%;
        clear: both;
    }
}

.footer-buttons {
    float: right;

    ion-button {
        --border-radius: 0;

        margin-left: 3px;
    }
}

.footer-clear-floats {
    // Clear floats
    &::after {
        content: '';
        display: table;
        width: 100%;
        clear: both;
    }
}

.footer-button-back {
    --background: transparent;

    font-size: 10px;
    line-height: 12px;
    float: left;

    .icon {
        display: inline-block;
        vertical-align: top;
        margin: 0 8px 0 0;
    }

    .button-inner {
        font-size: inherit;
        line-height: inherit;
    }
}

page-questionnaire,
page-questionnaire-chapters,
page-questionnaire-repeat-question,
page-send-questionnaire {
    ion-footer {
        ion-toolbar {
            --background: var(--theme-color-background);

            background-color: var(--theme-color-background);
        }
    }
}

.questionnaire-footer-wrap {
    background-color: var(--theme-color-background);
}

.questionnaire-footer {
    padding: 1.6rem 3.2rem 3.2rem;
}

.questionnaire-footer--compact {
    padding-top: .8rem;
    padding-bottom: .8rem;
}

.questionnaire-footer--task {
    padding-right: 1.6rem;
    padding-left: 1.6rem;
}

.questionnaire-footer__btns {
    margin: 0;
}

.questionnaire-footer__btns--full {
    width: 100%;
}

.questionnaire-footer__btn {
    border: .1rem solid var(--theme-color-light);
    border-radius: 2.4rem;
    color: var(--theme-color-light);
    font-size: 1.4rem;
    line-height: 1.14285714;
    width: 100% !important;
    height: auto !important;
    padding: .4rem;
    margin: 0;

    --padding-top: 1.1rem !important;
    --padding-end: 1.1rem !important;
    --padding-bottom: 1.1rem !important;
    --padding-start: 1.1rem !important;
    --margin: 0 !important;
}

.questionnaire-footer__btn--icon {
    border-radius: 50%;
    width: auto !important;
    height: auto !important;
    padding: 0;
}

.questionnaire-footer__btn--next {
    background: var(--theme-color-light);
    color: var(--theme-color-text);
}

.questionnaire-footer__btn--disabled {
    background: var(--theme-color-dark) !important;
    border-color: var(--theme-color-dark) !important;
    color: var(--theme-color-text) !important;
}

.questionnaire-footer__btn-icon {
    font-size: 2.4rem !important;
}

.questionnaire-footer__text {
    color: var(--theme-color-light);
    font-size: 1.6rem;
    line-height: 1.5;
    text-align: center;
}

.questionnaire-footer__text--step {
    font-size: 2.4rem;
    line-height: 1;
    font-weight: 600;
    position: relative;

    &::before {
        content: '';
        background: var(--theme-color-primary);
        border-radius: .1rem;
        width: 100%;
        height: .2rem;
        position: absolute;
        bottom: .1rem;
        left: 0;
    }
}
