@import "../variables";

page-password-change-form {
    .title {
        margin-bottom: 1.7rem;
    }

    ion-content {
        --background: var(--theme-color-light-grey);
    }

    ion-item.explanation {
        background-color: var(--theme-color-light-grey);
        font-size: 1.3rem;
    }
}
