app-notification-modal {
    display: block;
    font-size: 1.5rem;

    .notification {
        --theme-color: var(--theme-color-dark);
        --background: rgba(0,0,0,0);

        &--theme-primary {
            --theme-color: var(--theme-color-primary);
        }

        &--theme-danger {
            --theme-color: var(--theme-color-danger);
        }

        &__wrapper {
            position: relative;
            z-index: 1;
            background-color: #fff;
            padding: 0 0.8rem 0.8rem 0.8rem;
            margin: 4rem 1.5rem;
        }
        &__close-early {
            position: absolute;
            z-index: 2;
            top: 0;
            right: -0.4rem;
            padding: 1.4rem;
        }
        &__heading {
            position: relative;
            z-index: 1;
            font-weight: bold;
            text-align: center;
            padding: 1rem;
            border-bottom: 2px solid var(--theme-color);
            margin-bottom: 1.5rem;
        }
        &__subject {
            font-weight: bold;
            padding: 0 2rem 0.5rem 2rem;
        }
        &__body {
            padding: 0 2rem;
        }
        &__button {
            position: relative;
            background-color: var(--theme-color);
            cursor: pointer;
            padding: 0.3rem 0 0 0;
            line-height: 5rem;
            height: 5.5rem;
            font-weight: bold;
            text-align: center;
            margin-top: 1.5rem;
            color: #fff;

            &:hover,
            &:active {
                background-color: var(--theme-color);
            }
        }
    }

    .fixed-content {
        --background: rgba(0,0,0,0.6);
    }
}
