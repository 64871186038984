@import "../variables";

app-datetime {
    .title-container {
        display: inline-flex;
        align-items: center;

        color: var(--theme-color-primary);
        cursor: pointer;
    }
    .title {
        width: 100%;
        text-align: left;
        font-size: 3.6rem;
        margin: 0;
    }
    ion-icon {
        margin-left: 2rem;
        font-size: 3.6rem;
        padding: 1.6rem;
        border-radius: 100%;
        background-color: var(--theme-color-primary);
        color: var(--theme-color-light);
    }

    @media (max-width: 576px) { // TODO lookup xs size
        // smaller font-size than design to ensure 31st december 2017 fits small mobile screens
        .title {
            font-size: 2.4rem;
        }

        ion-icon {
            font-size: 2.4rem;
        }
    }
}
