@import "../variables";

.questionnaire-header__title {
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 600;
    color: var(--ion-color-contrast);
}

.questionnaire-header__recognize {
    flex: initial;
    font-size: 1.6rem;
    line-height: 1.5;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &::before {
        content: '';
        background: var(--theme-color-primary);
        border-radius: .1rem;
        width: 100%;
        height: .2rem;
        position: absolute;
        bottom: .1rem;
        left: 0;
    }
}
