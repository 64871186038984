@import "../variables";

page-registration {
    h2 {
        padding: 1rem 2rem;
    }

    .item-ios, .item-md {
        min-height: auto;
    }

    ion-item.explanation {
        --background: none;

        font-size: 1.3rem;

        .item-inner {
            border: none !important;
        }
    }

    .privacy-policy-checkbox {
        --background: none;

        font-size: 1.3rem;
        color: var(--theme-color-dark-grey);

        ion-label {
            white-space: normal;
            font-size: 1.3rem;
            color: var(--theme-color-dark-grey);
        }

        ion-checkbox {
            margin: 1.8rem 0.8rem 1.8rem 0 !important;
        }

        a {
            position: relative; // Without the link is not clickable (Ionic bug)

            color: var(--theme-color-dark);
            text-decoration: underline;
        }
    }
}

page-registration-complete, page-registration {
    .footer-md {
        padding-bottom: 1rem;
    }
}
