@import "../../styles/variables";

page-media-communication-details {
    .media-communication-details {
        padding: 1.8rem;

        &__image-wrapper {
            margin: -1.8rem -1.8rem 1.8rem -1.8rem;
        }

        &__image {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 0;
            padding: 56.25% 0 0 0; // 16:9 image ratio
            overflow: hidden;
            background-color: var(--theme-color-navbar);

            &::before {
                content: '';
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: block;
                box-shadow: inset 0 2px 70px 0 rgba(var(--theme-color-navbar-rgb), 0.6);
            }

            img {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: block;
                width: inherit;
                height: 100%;
                border: 0;
                margin: 0;
                object-fit: cover;
                opacity: 0.9;
            }
        }

        &__icon-video {
            line-height: 0;
            font-size: 0;
            position: absolute;
            z-index: 3;
            top: 50%;
            left: 50%;
            display: block;
            transform: translate(-50%, -50%);
            color: map_get($colors, 'danger');
            border-radius: 50%;
            overflow: hidden;
            box-shadow: inset 0 0 0 2px var(--theme-color-danger), 2px 2px 40px 0 rgba(0,0,0,0.5);
        }

        &__content {
            * {
                font-size: 15px;
                font-weight: normal;
                line-height: 1.5;
            }
            p {
                margin: 0 0 1.2rem 0;
            }
            em {
                font-style: italic;
            }
            strong {
                font-weight: bold;
            }
            ol, ul {
                padding: 0;
                margin: 0 0 1.2rem 0;
            }
            li {
                padding: 0;
                margin: 0 0 0 3.6rem;
            }
            img {
                display: block;
                margin: 0;
                border: 0;
                width: 100%;
                height: auto;
            }
        }
    }
}
