app-attachments-and-remarks {
    display: block;

    font-size: 0;

    margin-right: -.8rem;
    margin-left: -.8rem;
    position: relative;
}

.image-attachment {
    text-align: left;
    line-height: 51px;
    cursor: pointer;
    padding: 1.5rem 0;
    margin: 0 1.5rem;
    border-bottom: 1px solid rgba(0,0,0,0.1);

    .text-color-dark {
        display: inline-block;
        vertical-align: top;
    }
    .preview {
        display: inline-block;
        vertical-align: top;
        width: 150px;
        height: 51px;
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;
    }
    &--notification {
        line-height: 2rem;
        padding: 2rem 0;
    }
    &__icon,
    &__delete {
        float: right;
        margin-left: 10px;
    }
}

.remark {

    &__preview {
        text-align: left;
        clear: both;
    }
    &__icon,
    &__delete {
        float: right;
        margin-bottom: 1.5rem;
    }
}

.aar__item {
    border-top: .1rem solid var(--theme-color-border);
    margin-right: .8rem;
    margin-left: .8rem;
}

.aar__item--photo {
    padding-bottom: 1.6rem;
}

.aar__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--theme-color-text-gray);
    font-size: 1.2rem;
    line-height: 1.33333333;
    font-weight: 600;
    padding-top: 1.6rem;
    margin-bottom: 1.2rem;

    ion-button {
        color: var(--theme-color-danger);
        font-size: 1.2rem;
        height: auto;
        --padding-top: 0;
        --padding-end: 0;
        --padding-bottom: 0;
        --padding-start: 0;
    }
}

.aar__buttons {
    display: flex;
    align-items: center;
    border-top: .1rem solid var(--theme-color-border);

    ion-button {
        flex: 1;
    }
}

.aar_button-text {
    color: var(--theme-color-text);
}

.aar__line {
    background: var(--theme-color-border);
    width: .1rem;
    height: 2rem;
}
