@import "../variables";

ion-toast {
    --background: #{map-get($colors, 'text')};
    --button-color: #{map-get($colors, 'primary')};
    --color: #{map-get($colors, 'light')};
    --theme-color: #{map-get($colors, 'light')};
    --border-radius: 0;

    &.toast-theme-success {
        --theme-color: #{map-get($colors, 'success')};
    }

    &.toast-theme-error {
        --theme-color: #{map-get($colors, 'danger')};
    }

    &::part(button) {
        padding: 0;
        margin: 0 -0.5rem 0 1rem;
        color: var(--theme-color);
    }
}
