@import "../variables";

app-questionnaire-overlay {
    ion-content {
        --background: transparent;
    }

    .overlay-text{
        pointer-events: none;
        display:flex;
        height: 100%;
        align-items: flex-end;
        justify-content: flex-end;

        .text {
            font-size: 1.6rem;
            text-align: right;
            margin-right: 1rem;
            width: 70%;
            color: var(--theme-color-light);
            @include set-property-with-safe-area-inset-bottom(margin-bottom, 7rem);
        }
    }

    ion-footer .toolbar,
    ion-footer .toolbar-md,
    ion-footer .toolbar-ios {
        height: auto;
        min-height: 0;
    }

    .footer-clear-floats {
        background-color: rgba(0, 0, 0,.6);
    }

    .button-md, .button-ios {
        border-radius: 0;
    }

    .fixed-content {
        background-color: rgba(0, 0, 0,.6);
    }
}
