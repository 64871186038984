@import "../variables";

ion-item {
    --padding-start: #{$base-spacing};

    &.item-interactive.ion-invalid {
        --highlight-background: none;
    }
    &.item-interactive.ion-valid {
        --highlight-background: none;
    }

    h2 {
        font-size: 1.7rem;
        margin: 0 0 2px;
        font-weight: normal;
    }

    .container {
        width: 100%;
    }

    &.item__no-padding {
        --padding-start: 0;
        --inner-padding-end: 0;

        & + & {
            margin-top: 1.6rem;
        }
    }
}
