@import "../variables";

.wave-footer {
    border-top: .1rem solid map-get($colors, border-light);
}

.wave-footer__col {

}

.wave-footer__btn {
    height: auto !important;
    position: relative;

    &::before {
        display: block;
        content: '';
        background: var(--theme-color-light);
        width: 0;
        height: .2rem;
        position: absolute;
        top: -.9rem;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        transition: width .2s ease;
    }
}

.wave-footer__btn--active {

    &::before {
        width: 2.6rem;
    }
}

.wave-footer__btn-span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wave-footer__btn-text {
    color: rgba(map-get($colors, light), .64);
    font-size: 1.2rem;
    line-height: 2;

    .wave-footer__btn--active & {
        color: var(--theme-color-light);
    }
}

.wave-footer__icon-badge {
    color: rgba(map-get($colors, light), .64);
    width: 2.4rem;
    height: 2.4rem;
    position: relative;

    .wave-footer__btn--active & {
        color: var(--theme-color-light);
    }

    &::before {
        display: none;
        content: '';
        background: var(--theme-color-warning);
        border: .2rem solid map-get($colors, background);
        border-radius: 50%;
        width: .6rem;
        height: .6rem;
        position: absolute;
        top: 0;
        right: 0;
    }
}

.wave-footer__icon-badge--active {

    &::before {
        display: block;
    }
}
