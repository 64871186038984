app-photo {
    .single,
    .multiple {
        margin: 0 auto;
        .thumbnail {
            .options {
                background: var(--theme-color-danger);
                border-radius: .2rem;
                color: var(--theme-color-light);
                font-weight: bold;
                max-width: 100%;
                padding: .2rem .4rem;
                position: absolute;
                right: .4rem;
                bottom: .4rem;
                z-index: 2;
            }
        }
    }

    .take-picture-button {
        --border-radius: 0;

        margin: 0.5rem 3px;
    }
}

.dropzone {
    position: relative;
    border-radius: .4rem;
    border: .1rem dashed var(--theme-color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8.6rem;

    &:hover, &:active, &:focus-visible, &.dragging {
        cursor: pointer;
        background-color: var(--theme-color-grey);
    }

    ion-icon {
        color: var(--theme-color-primary);
        width: 2.4rem;
        height: 2.4rem;
    }

    input[type=file] {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
    }

    p {
        text-align: center;
        line-height: 20vh;
        position: relative;
        margin: 0;
    }

    /*&.dropzone--lean {
        color: var(--theme-color-dark);
        height: 5rem;
        width: calc(100% - 71px);
        display: inline-block;
        border-color: var(--theme-color-dark);

        p {
            line-height: 5rem;
        }
    }*/
}

.app-photo__grid {
    display: flex;
    flex-wrap: wrap;
    gap: .8rem;
}

.app-photo__item {
    flex: 0 0 8.6rem;
    height: 8.6rem;
    position: relative;
}

.app-photo__preview {
    border-radius: .4rem;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &.preview-disabled {
        bottom: 8px;
    }
}

.app-photo__footer {
    margin-top: 1.6rem;
}
