@import "../variables";

ion-input {
    --padding-end: 2rem !important;
    --padding-start: 2rem !important;
    --padding-top: 1.4rem !important;
    --padding-bottom: 1.4rem !important;
    background: map-get($colors, input-bg) !important;
    border-radius: .4rem;

    .item-highlight {
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
    }
}
