@import "../_variables.scss";

ion-badge {
    background: var(--theme-color-warning);
    color: var(--theme-color-light);
    border-radius: .2rem;
    font-size: 1.1rem;
    line-height: 1.45454545;
    padding: .1rem .6rem;

    &.badge--success {
        background: var(--theme-color-success);
    }
}
