@import "../variables";

page-questionnaire {
    h2 {

        margin-top: 0;
        margin-bottom: 0;
    }

    ion-footer .toolbar,
    ion-footer .toolbar-md,
    ion-footer .toolbar-ios {
        height: auto;
        min-height: 0;
    }
}

